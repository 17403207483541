import React from 'react'
import { Link } from "react-router-dom";


export default function Navbar() {
    return (
        <div className='Navbar'>
            <nav>
                <ul>
                    <li><Link className="link" to="/">Home</Link></li>
                    <li><Link className="link" to="/input">Input</Link></li>
                    <li><Link className="link" to="/stats">Stats</Link></li>
                    <li><Link className="link" to="/download">Download</Link></li>
                </ul>
            </nav>
        </div>
    )
}
