import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './page/Home';
import Input from './page/Input';
import Navbar from './components/Navbar';
import Words from './page/Words';
import Download from './page/Download';
import Stats from './page/Stats';


function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Words />} />
            {/* <Route path="/words" element={<Words />} /> */}
            <Route path="/input" element={<Input />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/download" element={<Download />} />

          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
