import React, { useEffect, useState } from 'react';

export default function Words() {
    const [products, setProducts] = useState(null);
    const [sorted, setSorted] = useState(false); // New state to track sorted order

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch("https://sunsetbay.art/db_for_testing/german/getdata.php");
                const data = await response.json();

                // Sort the products by 'created_at' date from newest to oldest
                const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                
                setProducts(sortedData);
                console.log('data from ')
                console.log(sortedData);
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        }
        fetchData();
    }, []);

    if (!products) {
        return <div>Loading...</div>; 
    }

    const sortWords = () => {
        const sortedProducts = [...products].sort((a, b) => 
            a.german_word.localeCompare(b.german_word)
        );
        setProducts(sortedProducts);
        setSorted(true); // Set sorted to true after sorting
    };

    const shuffleWords = () => {
        const shuffledProducts = [...products].sort(() => Math.random() - 0.5);
        setProducts(shuffledProducts);
        setSorted(false); // Reset sorted state when shuffled
    };

    const tableStyle = {
        width: '80%',
        margin: '20px auto',
        borderCollapse: 'collapse',
        textAlign: 'left'
    };

    const thStyle = {
        borderBottom: '2px solid #ccc',
        padding: '10px',
        backgroundColor: 'green',
        color: 'white'
    };

    const tdStyle = {
        borderBottom: '1px solid #ddd',
        padding: '10px'
    };

    return (
        <div>
            <button onClick={sortWords} disabled={sorted} style={{ margin: '20px', padding: '10px' }}>
                Sort Alphabetically
            </button>
            <button onClick={shuffleWords} style={{ margin: '20px', padding: '10px' }}>
                Sort Randomly
            </button>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>German Word</th>
                        <th style={thStyle}>English Translation</th>
                        <th style={thStyle}>Sentence</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(item => (
                        <tr key={item.id}>
                            <td style={tdStyle}><b>{item.german_word}</b></td>
                            <td style={tdStyle}>{item.english_translation}</td>
                            <td style={tdStyle}>{item.sentence}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
