import React, { useEffect, useState } from 'react';


export default function Stats() {

  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
        try {
            // const response = await fetch("https://sunsetbay.art/db_for_testing/german/getdata.php");
            const response = await fetch(process.env.REACT_APP_GET_WORDS);
            const data = await response.json();
    
            setProducts(data);
        } catch (error) {
            console.error('Failed to fetch products:', error);
        }
    }
    fetchData();
}, []);


  return (
    <div>Total number of  words is : {products.length} </div>
  )
}
